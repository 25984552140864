import { makeStyles, Typography, MenuItem } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  main: {
    background: theme.palette.primary.secondary,
    color: theme.palette.text.main,
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    height: "95%",
    overflow: "none",
    paddingTop: "10%",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  button: {
    width: "80%",
    padding: "7.5%",
    backgroundColor: theme.palette.primary.tertiary,
    color: "white",
    border: "2px solid white",
    borderRadius: 20,
    justifyContent: "center",
  },
  backButton: {
    maxHeight: "10%",
    width: "80%",
    padding: "5%",
    backgroundColor: "white",
    justifyContent: "center",
    color: theme.palette.primary.tertiary,
    borderRadius: 20,
  },
}));

export default function Settings() {
  const classes = useStyles();

  return(
    <div
      className={classes.main}
    >
      <Typography
        style={{
          fontSize: 20,
          fontWeight: 600,
        }}
      >
        Instellingen
      </Typography>
      <div
        className={classes.row}
        style={{
          paddingTop: "10%",
        }}
      >
        <Typography>
          Ruimte voor instellingen
        </Typography>
      </div>

      <div
        className={classes.row}
        style={{
          paddingTop: "10%",
        }}
      >
        <MenuItem
          className={classes.button}
          component={Link}
          disabled="True"
          to={
            "/settings"
          }
        >
          <Typography
            style={{
              fontWeigth: 600,
              justifyContent: "center",
            }}
          >
            Profiel instellingen
          </Typography>
        </MenuItem>


      </div>

      <div
        className={classes.row}
        style={{
          paddingTop: "5%",
        }}
      >
        <MenuItem
          className={classes.button}
          component={Link}
          disabled="True"
          to={
            "/settings"
          }
        >
          <Typography
            style={{
              fontWeigth: 600,
              justifyContent: "center",
            }}
          >
            Melding instellingen
          </Typography>
        </MenuItem>


      </div>

      <div
        className={classes.row}
        style={{
          paddingTop: "5%",
        }}
      >
        <MenuItem
          className={classes.button}
          component={Link}
          disabled="True"
          to={
            "/settings"
          }
        >
          <Typography
            style={{
              fontWeigth: 600,
              justifyContent: "center",
            }}
          >
            Kleur instellingen
          </Typography>
        </MenuItem>
      </div>

      <div
        className={classes.row}
        style={{
          paddingTop: "10%",
          paddingBottom: "10%",
        }}
      >
        <MenuItem
          className={classes.backButton}
          component={Link}
          to={
            "/"
          }
        >
          <Typography
            style={{
              fontWeight: 600,
              justifyContent: "center",
            }}
          >
            	Terug naar home
          </Typography>
        </MenuItem>
      </div>
    </div>
  
    );
}
