import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import API_URL from "../API_URL";
import { Redirect } from "react-router-dom";

export default function Register() {
  const useStyles = makeStyles((theme) => ({
    main: {
      background: theme.palette.primary.main,
      color: theme.palette.text.main,
      textAlign: "center",
      minHeight: "100%",
      paddingTop: "10%",
      display: "flex",
      flexDirection: "column",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "center",
    },
    textRow: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "left",
    },
    text: {
      fontSize: "20px",
      paddingLeft: "10%",
    },
    input: {
      position: "relative",
      width: "80%",
      color: "white",
    },
    inputOutline: {
      borderColor: "white",
      borderWidth: 2,
      color: "white",
    },
    buttonInput: {
      position: "relative",
      width: "80%",
      backgroundColor: "white",
      color: theme.palette.primary.main,
      fontSize: "20px",
      fontWeight: "600",
      textTransform: "none",
      borderRadius: 8,
    },
    errDialogOkButton: {
      width: "100%",
      backgroundColor: theme.palette.button.main,
      color: "white",
      borderRadius: 20,
      justifyContent: "center",
    },
  }));

  var data = {
    firstName: "",
    lastName: "",
    email: "",
    key: "",
  };

  var confirmKey = "";

  const [nextPage, setNextPage] = useState(false);

  const [open, setOpen] = useState(false);
  const [err, setErr] = useState("");

  const handleClose = () => {
    setErr("");
    setOpen(false);
  };

  async function HandleFormSubmit(event) {
    event.preventDefault();

    if (data.key === confirmKey) {
      await axios
        .post(API_URL + "/register", data)
        .then((res) => {
          setNextPage(true);
          console.log("account registered");
          console.log("should redirect");
        })
        .catch((err) => {
          let status = err.response.status;
          console.log("error: " + err.response.status);
          if (status === 401) {
            console.log("duplicate email");
            setOpen(true);
            setErr("Er bestaat al een account met dit email adres.");
            return;
          }
          //setErr(err);
        });
    } else {
      setOpen(true);
      setErr("Wachtwoorden komen niet overeen.");
    }
  }

  const classes = useStyles();

  if (nextPage) {
    return <Redirect to="/login" />;
  } else {
    return (
      <div className={classes.main}>
        <form onSubmit={(event) => HandleFormSubmit(event)}>
          <div className={classes.row}>
            <Typography
              style={{
                fontSize: "32px",
              }}
            >
              Aanmelden
            </Typography>
          </div>
          <div
            className={classes.textRow}
            style={{
              paddingTop: "10%",
            }}
          >
            <Typography className={classes.text}>Voornaam</Typography>
          </div>
          <div className={classes.row}>
            <TextField
              className={classes.input}
              InputProps={{
                classes: { notchedOutline: classes.inputOutline },
              }}
              placeholder="Je voornaam"
              type="text"
              id="name"
              variant="outlined"
              onChange={(event) => (data.firstName = event.target.value)}
            />
          </div>

          <div
            className={classes.textRow}
            style={{
              paddingTop: "5%",
            }}
          >
            <Typography className={classes.text}>Achternaam</Typography>
          </div>
          <div className={classes.row}>
            <TextField
              className={classes.input}
              InputProps={{
                classes: { notchedOutline: classes.inputOutline },
              }}
              placeholder="Je achternaam"
              type="text"
              id="name"
              variant="outlined"
              onChange={(event) => (data.lastName = event.target.value)}
            />
          </div>

          <div
            className={classes.textRow}
            style={{
              paddingTop: "5%",
            }}
          >
            <Typography className={classes.text}>Email</Typography>
          </div>
          <div className={classes.row}>
            <TextField
              className={classes.input}
              InputProps={{
                classes: { notchedOutline: classes.inputOutline },
              }}
              placeholder="Je E-mail adres"
              type="text"
              id="email"
              variant="outlined"
              onChange={(event) => (data.email = event.target.value)}
            />
          </div>

          <div
            className={classes.textRow}
            style={{
              paddingTop: "5%",
            }}
          >
            <Typography className={classes.text}>Wachtwoord</Typography>
          </div>
          <div className={classes.row}>
            <TextField
              className={classes.input}
              InputProps={{
                classes: { notchedOutline: classes.inputOutline },
              }}
              placeholder="Je wachtwoord"
              type="password"
              id="password"
              variant="outlined"
              onChange={(event) => (data.key = event.target.value)}
            />
          </div>

          <div
            className={classes.textRow}
            style={{
              paddingTop: "5%",
            }}
          >
            <Typography className={classes.text}>Herhaal wachtwoord</Typography>
          </div>
          <div className={classes.row}>
            <TextField
              className={classes.input}
              InputProps={{
                classes: { notchedOutline: classes.inputOutline },
              }}
              placeholder="Herhaal wachtwoord"
              type="password"
              id="password"
              variant="outlined"
              onChange={(event) => (confirmKey = event.target.value)}
            />
          </div>

          <div className={classes.row}>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle
                style={{
                  textAlign: "center",
                  fontWeight: 600,
                }}
              >
                <Typography
                  style={{
                    fontWeight: 600,
                  }}
                >
                  OEPS!
                </Typography>
              </DialogTitle>
              <DialogContent>
                <Typography
                  style={{
                    textAlign: "center",
                  }}
                >
                  {err}
                </Typography>
              </DialogContent>
              <DialogActions>
                <div
                  // style={{
                  //   display: "flex",
                  //   flexDirection: "row",
                  //   width: "100%",
                  // }}
                  className={classes.errDialogOkButton}
                >
                  <Button
                    onClick={handleClose}
                    style={{
                      width: "100%",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: 16,
                        fontWeight: 600,
                        textAlign: "center",
                        color: "white",
                      }}
                    >
                      Oké
                    </Typography>
                  </Button>
                </div>
              </DialogActions>
            </Dialog>
          </div>

          <div
            className={classes.row}
            style={{
              paddingTop: "10%",
              paddingBottom: "5%",
            }}
          >
            <Button
              className={classes.buttonInput}
              type="submit"
              variant="contained"
              color="primary"
            >
              Aanmelden
            </Button>
          </div>
        </form>
      </div>
    );
  }
}
