import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  makeStyles,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import axios from "axios";
import Load from "../../load";
import { useLocation } from "react-router";
import API_URL from "../../../API_URL";
import { Link, Redirect } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import CropFreeIcon from "@material-ui/icons/CropFree";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles((theme) => ({
  main: {
    background: theme.palette.primary.main,
    color: theme.palette.text.main,
    textAlign: "center",
    minHeight: "100%",
    paddingTop: "10%",
    maxWidth: "100%",
    Display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  content: {
    background: theme.palette.primary.secondary,
    borderTopRightRadius: "20px",
    borderTopLeftRadius: "20px",
    height: "100%",
  },
  button: {
    maxHeight: "20%",
    width: "80%",
    padding: "7.5%",
    backgroundColor: theme.palette.primary.tertiary,
    color: "white",
    border: "2px solid white",
    borderRadius: 20,
  },
  backButton: {
    maxHeight: "10%",
    width: "80%",
    padding: "5%",
    backgroundColor: "white",
    justifyContent: "center",
    color: theme.palette.primary.tertiary,
    borderRadius: 20,
  },
}));

async function GetData(id, secret) {
  const response = await axios
    .get(API_URL + "/profile/view", {
      params: {
        id: id,
        secret: secret,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("errrror: " + err);
    });

  return response;
}

export default function ProfileActions() {
  const classes = useStyles();
  let query = useQuery();
  const profileId = query.get("profileId");
  const secret = query.get("secret");

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  //delete dialog stuff
  const [open, setOpen] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  //

  useEffect(async () => {
    try {
      setLoading(true);
      const dt = await GetData(profileId, secret);
      setData(dt);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, []);

  if (loading) return <Load />;
  if (!data) return <div className={classes.main}>error</div>;

  const item = data.data[0];

  const handleDelete = () => {
    axios
      .post(API_URL + "/profile/delete", {
        profileId: item.id,
        secret: item.secret,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log("error: " + err);
      });
    setOpen(false);
    setDeleted(true);
  };
  if (deleted) {
    return <Redirect to="/profile" />;
  }
  return (
    <div className={classes.main}>
      <div className={classes.row}>
        <div className={classes.column}>
          <Typography
            style={{
              fontSize: 20,
              fontWeight: 600,
            }}
          >
            Acties voor het profiel van:
          </Typography>
          <Typography
            style={{
              fontSize: 20,
              fontWeight: 600,
              paddingTop: "5%",
              paddingBottom: "5%",
            }}
          >
            {item.first_name}
          </Typography>
        </div>
      </div>
      <div className={classes.content}>
        <div
          className={classes.row}
          style={{
            paddingTop: "10%",
          }}
        >
          <MenuItem
            className={classes.button}
            component={Link}
            to={"/profile/view?profileId=" + item.id + "&secret=" + item.secret}
          >
            <VisibilityIcon
              style={{
                marginRight: "20%",
              }}
            ></VisibilityIcon>
            <Typography
              style={{
                fontWeight: "600",
                justifyContent: "center",
              }}
            >
              Bekijk Profiel
            </Typography>
          </MenuItem>
        </div>

        <div
          className={classes.row}
          style={{
            paddingTop: "10%",
          }}
        >
          <MenuItem
            className={classes.button}
            component={Link}
            to={
              "/createProfile/step0?profileId=" +
              item.id +
              "&secret=" +
              item.secret
            }
          >
            <EditIcon
              style={{
                marginRight: "20%",
              }}
            ></EditIcon>
            <Typography
              style={{
                fontWeight: "600",
                justifyContent: "center",
              }}
            >
              Bewerk profiel
            </Typography>
          </MenuItem>
        </div>

        <div
          className={classes.row}
          style={{
            paddingTop: "10%",
          }}
        >
          <MenuItem
            className={classes.button}
            component={Link}
            to={
              "/profile/actions/shareQr?profileId=" +
              item.id +
              "&secret=" +
              item.secret
            }
          >
            <CropFreeIcon
              style={{
                marginRight: "20%",
              }}
            ></CropFreeIcon>
            <Typography
              style={{
                fontWeight: "600",
                justifyContent: "center",
              }}
            >
              Deel QR Code
            </Typography>
          </MenuItem>
        </div>

        <div
          className={classes.row}
          style={{
            paddingTop: "10%",
          }}
        >
          <Button className={classes.button} onClick={handleClickOpen}>
            <CloseIcon
              style={{
                marginRight: "20%",
              }}
            ></CloseIcon>
            <Typography
              style={{
                fontWeight: "600",
                justifyContent: "center",
              }}
            >
              Verwijder Profiel
            </Typography>
          </Button>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle
              style={{
                textAlign: "center",
              }}
            >
              Profiel Verwijderen?
            </DialogTitle>
            <DialogActions>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Button
                  onClick={handleClose}
                  style={{
                    width: "50%",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: 16,
                      fontweight: 600,
                    }}
                  >
                    Nee
                  </Typography>
                </Button>
                <Button
                  onClick={handleDelete}
                  style={{
                    width: "50%",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: 16,
                      fontweight: 600,
                    }}
                  >
                    Ja
                  </Typography>
                </Button>
              </div>
            </DialogActions>
          </Dialog>
        </div>

        <div
          className={classes.row}
          style={{
            paddingTop: "10%",
            paddingBottom: "10%",
          }}
        >
          <MenuItem
            className={classes.backButton}
            component={Link}
            to={"/profile"}
          >
            <Typography
              style={{
                fontWeight: "600",
                justifyContent: "center",
              }}
            >
              Terug naar overzicht
            </Typography>
          </MenuItem>
        </div>
      </div>
    </div>
  );
}
