import {
  makeStyles,
  Typography,
  Button,
  Input,
  TextField,
  TextareaAutosize,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import { Redirect } from "react-router";
import FileUploadComponent from "../../media/FileUploadComponent";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import API_URL from "../../../API_URL";
import axios from "axios";
import Load from "../../load";

const useStyles = makeStyles((theme) => ({
  main: {
    background: theme.palette.primary.main,
    color: theme.palette.text.main,
    textAlign: "center",
    minHeight: "90%",
    paddingTop: "5%",
  },
  topContent: {
    position: "relative",
    left: "10%",
    width: "80%",
  },
  innerContent: {
    padding: "5%",
  },
  innerLeftContent: {
    textAlign: "left",
    height: "100%",
  },
  input: {
    fontSize: 20,
    width: "100%",
    border: "1px dotted black",
  },
  inputContainer: {
    width: "100%",
    height: "10%",
  },
  bottomContent: {
    padding: "5%",
    maxHeight: "20%",
    left: "10%",
    width: "90%",
    backgroundColor: "white",
    backgroundClip: "content-box",
  },
  button: {
    width: "100%",
    backgroundColor: theme.palette.button.main,
    color: "white",
    borderRadius: 20,
    justifyContent: "center",
  },
  addButton: {
    width: "30%",
    backgroundColor: theme.palette.button.main,
    color: "white",
    fontWeight: "600",
    borderTopRightRadius: "20px",
    borderBottomRightRadius: "20px",
  },
  text: {
    color: theme.palette.primary.tertiary,
    width: "90%",
    textAlign: "left",
    padding: "5%",
    border: "none",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  row: {
    paddingTop: "5%",
    width: "100%",
  },
}));

async function GetData(id, endpoint) {
  const response = await axios
    .get(API_URL + "/profile/" + endpoint, {
      params: {
        profileId: id,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });

  return response;
}

export default function Step1() {
  const classes = useStyles();
  const profile = useStoreState((state) => state.createProfile);

  const [loading, setLoading] = useState(false);

  const [items, setItems] = useState([]);

  const [inputText, setInputText] = useState("");
  const [inputImage, setInputImage] = useState("");
  const [inputImageType, setInputImageType] = useState("");

  const [nextPage, setNextPage] = useState(false);

  const pageType = useStoreState((state) => state.createProfileState);

  const [title, setTitle] = useState("");
  const [err, setErr] = useState("");

  //dialog stuff
  const [open, setOpen] = useState(false);
  const [errOpen, setErrOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setErr("");
    setOpen(false);
  };

  const handleErrClose = () => {
    setErrOpen(false);
  };

  if (title == "") {
    switch (parseInt(pageType)) {
      case 0:
        setTitle("Profiel aanmaken");
        break;
      case 1:
        setTitle("Informatie over mij");
        break;
      case 2:
        setTitle("Dingen die ik niet leuk vind");
        break;
      case 3:
        setTitle("Dingen die ik leuk vind");
        break;
      default:
        break;
    }
  }

  useEffect(async () => {
    if (profile.profileId > 0) {
      try {
        setLoading(true);
        let requestData;
        switch (parseInt(pageType)) {
          case 1:
            requestData = await GetData(profile.profileId, "abouts");
            break;
          case 2:
            requestData = await GetData(profile.profileId, "dislikes");
            break;
          case 3:
            requestData = await GetData(profile.profileId, "likes");
            break;
          default:
            await GetData(profile.profileId, "abouts");
            break;
        }
        if (requestData) {
          let arr = [];
          let count = 0;
          requestData.data.data.map((rd) => {
            arr.push({
              id: count++,
              profileId: parseInt(rd.profile_id),
              data: rd.data,
              type: parseInt(rd.type),
              media: rd.media,
              mediaType: rd.media_type,
            });
          });
          setItems([...arr]);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  }, []);

  const addItem = (event) => {
    event.preventDefault();

    if (inputText == "") {
      setErr("je hebt nog geen tekst toegevoegd!");
    } else {
      setItems([
        ...items,
        {
          id: items.length,
          profileId: profile.profileId,
          data: inputText,
          type: parseInt(pageType) - 1,
          media: inputImage,
          mediaType: inputImageType,
        },
      ]);
      if (open) {
        handleClose();
      }
      setInputText("");
      setInputImage("");
      setInputImageType("");
      setErr("");
    }
  };

  function handleChange(event, item) {
    const text = event.target.value;
    const newItems = items.map((i) => {
      if (i.id == item.id) {
        const updatedItem = { ...i };
        updatedItem.data = text;
        return updatedItem;
      }
      return i;
    });
    setItems([...newItems]);
  }

  //upload image from FileUploadComponent button
  function fileUploadCallback(event, item) {
    item.media = event.image;
    item.mediaType = "image";
  }

  //upload image from dialog
  function DialogFileUploadCallback(event) {
    setInputImage(event.image);
  }

  //remove profile entry
  const removeItem = (event, key) => {
    event.preventDefault();
    items.splice(key, 1);
    let arr = [];
    let count = 0;
    items.map((item) => {
      arr.push({
        id: count++,
        profileId: item.profileId,
        data: item.data,
        type: item.type,
        media: item.media,
        mediaType: item.mediaType,
      });
    });
    setItems([...arr]);
  };

  const saveEntries = useStoreActions((actions) => actions.saveEntries);
  const setProfileState = useStoreActions((actions) => actions.setProfileState);

  const FormSubmit = (event) => {
    event.preventDefault();
    if (items.length == 0) {
      setErrOpen(true);
      setErr("Je hebt nog niks ingevoerd!");
    } else if (inputText != "") {
      setErr("Je hebt nog tekst open staan");
    } else {
      saveEntries(items);
      setNextPage(true);
      setProfileState(parseInt(pageType) + 1);
    }
  };

  let displayData = null;

  if (loading) {
    displayData = <Load />;
  } else {
    //Function to render added profile entries on page
    displayData = items.map((item) => {
      return (
        <div
          key={item.id}
          style={{
            width: "100%",
            height: "50%",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              paddingTop: "5%",
              textAlign: "center",
              height: "100%",
              width: "85%",
            }}
          >
            <div
              key={item.id}
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "white",
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "3px",
                marginBottom: "1vh",
              }}
            >
              <FileUploadComponent
                style={{
                  width: "100%",
                  textAlign: "left",
                }}
                isShowImage={true}
                changeMode={false}
                inputImage={item.media}
                onFileUploaded={(event) => fileUploadCallback(event, item)}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <TextareaAutosize
                  className={classes.text}
                  disabled={true}
                  onChange={(event) => handleChange(event, item)}
                  value={item.data}
                ></TextareaAutosize>
                <Button
                  onClick={(e) => removeItem(e, item.id)}
                  style={{
                    width: "10%",
                    backgroundColor: "red",
                    color: "white",
                    alignContent: "right",
                    textAlign: "right",
                    borderBottomLeftRadius: "3px",
                    borderBottomRightRadius: "20px",
                  }}
                >
                  X
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  console.log("pageType " + pageType);
  if (nextPage) {
    return <Redirect to="/createProfile" />;
  }
  return (
    <div className={classes.main}>
      <div className={classes.topContent}>Stap {pageType + 1}</div>
      <div className={classes.innerContent}>
        <Typography>{title}</Typography>

        <div className={classes.innerContent}>{displayData}</div>

        <div className={classes.innerLeftContent}>
          <div className={classes.inputContainer}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              {/* dialog stuff */}
              <Button
                className={classes.button}
                variant="outlined"
                onClick={handleClickOpen}
              >
                <Typography
                  style={{
                    fontSize: 20,
                    fontWeight: 600,
                  }}
                >
                  Informatie toevoegen
                </Typography>
              </Button>
              <Dialog open={open} onClose={handleClose}>
                <DialogTitle
                  style={{
                    textAlign: "center",
                  }}
                >
                  Informatie toevoegen
                </DialogTitle>
                <DialogContent>
                  <div
                    className={classes.column}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: 12,
                        fontWeight: 600,
                      }}
                    >
                      Voer tekst in die je op deze pagina van je profiel wilt
                      weergeven.
                    </Typography>
                    <TextareaAutosize
                      className={classes.input}
                      placeholder="Korte text"
                      value={inputText}
                      onChange={(event) => setInputText(event.target.value)}
                      rowsMin={3}
                      rowsMax={5}
                    ></TextareaAutosize>
                    <div
                      className={classes.row}
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: 12,
                          fontWeight: 600,
                          paddingBottom: "2%",
                        }}
                      >
                        Als je een foto aan de tekst wil toevoegen, klik dan op
                        de 'Foto Uploaden' knop hieronder.
                      </Typography>
                      <FileUploadComponent
                        style={{
                          width: "100%",
                          textAlign: "left",
                        }}
                        isShowImage={true}
                        changeMode={true}
                        inputImage={inputImage}
                        onFileUploaded={(event) =>
                          DialogFileUploadCallback(event)
                        }
                      />
                      <Typography
                        style={{
                          color: "red",
                          fontWeight: 600,
                        }}
                      >
                        {err}
                      </Typography>
                    </div>
                  </div>
                </DialogContent>
                <DialogActions>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <Button
                      onClick={handleClose}
                      style={{
                        width: "50%",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: 16,
                          fontweight: 600,
                        }}
                      >
                        Verwijder
                      </Typography>
                    </Button>
                    <Button
                      onClick={addItem}
                      style={{
                        width: "50%",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: 16,
                          fontweight: 600,
                        }}
                      >
                        Bevestig
                      </Typography>
                    </Button>
                  </div>
                </DialogActions>
              </Dialog>

              {/* dialog stuff end */}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            paddingTop: "15%",
          }}
        >
          <Dialog open={errOpen} onClose={handleErrClose}>
            <DialogTitle
              style={{
                textAlign: "center",
              }}
            >
              <Typography
                style={{
                  fontWeight: 600,
                  textAlign: "center",
                }}
              >
                LET OP!
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Typography
                style={{
                  textAlign: "center",
                }}
              >
                {err}
              </Typography>
            </DialogContent>
            <DialogActions>
              <div
                // style={{
                //   display: "flex",
                //   flexDirection: "row",
                //   width: "100%",
                //   border: "1px solid black",
                //   borderRadius: "20px",
                // }}
                className={classes.button}
              >
                <Button
                  onClick={handleErrClose}
                  style={{
                    width: "100%",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: 18,
                      fontweight: 600,
                      textAlign: "center",
                      color: "white",
                    }}
                  >
                    Oké
                  </Typography>
                </Button>
              </div>
            </DialogActions>
          </Dialog>

          {/* <Typography
            style={{
              fontSize: 20,
              fontWeight: 600,
              color: "red",
            }}
          >
            {err}
          </Typography> */}
        </div>
        <div
          style={{
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
          }}
        >
          <Button onClick={FormSubmit} className={classes.button}>
            <div
              style={{
                padding: "1%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography
                style={{
                  fontWeight: 600,
                }}
              >
                Volgende Stap
              </Typography>
              <ArrowRightIcon
                style={{
                  justifyConten: "center",
                }}
              ></ArrowRightIcon>
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
}
