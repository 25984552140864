import { useStoreState } from "easy-peasy";
import { Redirect } from "react-router";

export default function ViewProfile() {
  const pageType = parseInt(useStoreState((state) => state.pageType));

  console.log("viewProfile");
  console.log(pageType);

  switch (pageType) {
    case 0:
      return <Redirect exact to={"profile/view/about"} />;
    case 1:
      return <Redirect exact to={"profile/view/about"} />;
    case 2:
      return <Redirect exact to={"profile/view/about"} />;
    default:
      return <Redirect exact to={"profile/view/confirm"} />;
  }
}
