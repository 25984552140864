import { makeStyles, Typography, Button } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import API_URL from "../../API_URL";
import { useState } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import React from "react";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SentimentSatisfiedOutlinedIcon from "@material-ui/icons/SentimentSatisfiedOutlined";
import LoadView from "../loadView";

const useStyles = makeStyles((theme) => ({
  main: {
    background: theme.palette.primary.main,
    color: theme.palette.text.main,
    textAlign: "center",
    minHeight: "100%",
  },
  bottomContent: {
    position: "relative",
    width: "100%",
    paddingTop: "5%",
    paddingBottom: "5%",
  },
  button: {
    position: "relative",
    width: "85%",
    backgroundColor: theme.palette.button.main,
    color: "white",
    fontWeight: "600",
    borderRadius: 20,
    justifyContent: "center",
  },
  textContent: {
    background: theme.palette.primary.secondary,
    borderTopRightRadius: "20px",
    borderTopLeftRadius: "20px",
  },
  mediaContent: {
    paddingTop: "2%",
    background: theme.palette.primary.tertiary,
    borderTopRightRadius: "20px",
    borderTopLeftRadius: "20px",
  },
  nameContent: {
    paddingTop: "5%",
    paddingBottom: "5%",
    textAlign: "center",
    height: "100%",
    width: "100%",
  },
  innerContent: {
    paddingTop: "5%",
    textAlign: "center",
    height: "100%",
    width: "85%",
  },
  mediaContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    color: theme.palette.primary.tertiary,
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    borderBottomRightRadius: "20px",
    borderBottomLeftRadius: "3px",
    marginBottom: "1vh",
  },
  buttonContainer: {
    alignItems: "center",
    width: "100%",
  },
  mediaPicture: {
    maxWidth: "100%",
    width: "100%",
    maxHeight: "100%",
    height: "100%",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
  },
  textContainer: {
    textAlign: "left",
    padding: "5%",
  },
}));

async function GetData(id, endpoint) {
  const response = await axios
    .get(API_URL + "/profile/" + endpoint, {
      params: {
        profileId: id,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });

  return response;
}

var dataList = [];

function RetrieveData(profileId, endpoint) {
  const [data, setData] = useState(null);

  useEffect(async () => {
    if (!data) {
      try {
        const requestData = await GetData(profileId, endpoint);
        setData(requestData);
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  if (data) {
    const aboutList = data.data.data;
    dataList = [];
    Object.keys(aboutList).forEach(function (key) {
      dataList.push(aboutList[key]);
    });
  }
}

export default function About() {
  const classes = useStyles();

  const [nextPage, setNextPage] = useState(false);

  var buttonText = " ";
  const profileName = useStoreState((state) => state.profileName);
  const profileBirth = useStoreState((state) => state.profileBirth);

  const profileId = useStoreState((state) => state.profileId);

  const pageType = parseInt(useStoreState((state) => state.pageType));
  const setPageType = useStoreActions((actions) => actions.setPageType);

  var headerText = null;

  switch (pageType) {
    case 0:
      buttonText = "Dingen die ik niet leuk vind";
      headerText = () => {
        return (
          <div>
            <Typography
              style={{
                fontSize: "26px",
              }}
            >
              Ik ben {profileName},
            </Typography>
            <Typography
              style={{
                fontSize: "26px",
              }}
            >
              en ben {profileBirth} jaar.
            </Typography>
          </div>
        );
      };
      RetrieveData(profileId, "abouts");
      break;
    case 1:
      buttonText = "Dingen die ik leuk vind";
      headerText = () => {
        return (
          <div>
            <SentimentVeryDissatisfiedIcon
              style={{
                fontSize: "60px",
              }}
            />
            <Typography
              style={{
                fontSize: "26px",
              }}
            >
              Dit vind ik
            </Typography>
            <Typography
              style={{
                fontSize: "26px",
              }}
            >
              niet leuk.
            </Typography>
          </div>
        );
      };
      RetrieveData(profileId, "dislikes");
      break;
    case 2:
      buttonText = "Volgende";
      headerText = () => {
        return (
          <div>
            <SentimentSatisfiedOutlinedIcon
              style={{
                fontSize: "60px",
              }}
            />
            <Typography
              style={{
                fontSize: "26px",
              }}
            >
              Dit vind ik
            </Typography>
            <Typography
              style={{
                fontSize: "26px",
              }}
            >
              wel leuk.
            </Typography>
          </div>
        );
      };
      RetrieveData(profileId, "likes");
      break;
    default:
      RetrieveData(profileId, "likes");
      break;
  }

  const FormSubmit = (event) => {
    event.preventDefault();
    setPageType(pageType + 1);
    setNextPage(true);
  };

  var textList;
  if (dataList.length === 0) {
    textList = (
      <div
        key={"0"}
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
        }}
      >
        <div className={classes.innerContent}>
          <LoadView />
        </div>
      </div>
    );
  } else {
    textList = dataList.map((item) => {
      if (item.media == "") {
        return (
          <div
            key={"text " + item.id}
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div className={classes.innerContent}>
              <div key={"media" + item.id} className={classes.mediaContainer}>
                <div className={classes.textContainer}>
                  <Typography
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    {item.data}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return <div></div>;
      }
    });
  }

  const mediaList = dataList.map((item) => {
    if (item.media != "") {
      return (
        <div
          key={"media" + item.id}
          style={{
            width: "100%",
            height: "50%",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className={classes.innerContent}>
            <div key={item.id} className={classes.mediaContainer}>
              <img src={item.media} className={classes.mediaPicture}></img>
              <div className={classes.textContainer}>
                <Typography
                  style={{
                    fontWeight: 800,
                  }}
                >
                  {item.data}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div> </div>;
    }
  });

  if (nextPage) {
    dataList = [];
    return <Redirect to={"/viewProfile"} />;
  } else {
    return (
      <div className={classes.main}>
        <div className={classes.nameContent}>{headerText()}</div>

        <div className={classes.textContent}>
          <div
            style={{
              paddingBottom: "5%",
            }}
          >
            {textList}
          </div>

          <div className={classes.mediaContent}>
            {mediaList}
            <div className={classes.bottomContent}>
              <Button onClick={FormSubmit} className={classes.button}>
                <div
                  style={{
                    padding: "1%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography style={{ fontWeight: 600 }}>
                    {buttonText}
                  </Typography>

                  <ArrowRightIcon
                    style={{
                      justifyConten: "center",
                    }}
                  ></ArrowRightIcon>
                </div>
              </Button>
            </div>
          </div>
        </div>

        {/* <div className={classes.bottomContent}>
          <Button onClick={FormSubmit} className={classes.button}>
            {buttonText}
          </Button>
        </div> */}
      </div>
    );
  }
}
