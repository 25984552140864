import { useStoreState } from "easy-peasy";
import { Redirect } from "react-router";

export default function CreateProfile() {
  const profileState = useStoreState((state) => state.createProfileState);

  switch (profileState) {
    case 0:
      return <Redirect to="/createProfile/step0" />;
    case 1:
      return <Redirect exact to="/createProfile/step1" />;
    case 2:
      return <Redirect exact to="/createProfile/step1" />;
    case 3:
      return <Redirect exact to="/createProfile/step1" />;
    case 4:
      return <Redirect to="/createProfile/finalize" />;
    default:
      return <Redirect to="/login" />
  }
}
