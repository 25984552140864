import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import API_URL from "../../../API_URL";
import { useStoreState } from "easy-peasy";
import { makeStyles, MenuItem, Typography } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { Link } from "react-router-dom";
import Load from "../../load";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    height: "90%",
    width: "100%",
    paddingTop: "10%",
    background: theme.palette.primary.main,
    color: theme.palette.text.main,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
  },
  button: {
    width: "85%",
    backgroundColor: theme.palette.button.main,
    color: "white",
    fontWeight: "600",
    borderRadius: 20,
    justifyContent: "center",
  },
}));

async function SumbitData(data) {
  await axios
    .post(API_URL + "/profile/create/entry/del", data)
    .then((res) => {
      console.log("deleted data");
      return true;
    })
    .catch((err) => {
      console.log("error: " + err);
      return false;
    });
  await axios
    .post(API_URL + "/profile/create/entry/new", data)
    .then((res) => {
      console.log("added items:");
      console.log(data.entries);
      return true;
    })
    .catch((err) => {
      console.log("error: " + err);
      return false;
    });
}

function Finalize() {
  const classes = useStyles();
  const profileData = useStoreState((state) => state.createProfile);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(true);

  console.log("test");

  useEffect(async () => {
    try {
      console.log("finalize");
      setLoading(true);
      const submit = await SumbitData(profileData);
      if (submit) {
        setData(true);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, []);

  if (loading) return <Load />;

  if (data) {
    return (
      <div className={classes.main}>
        <div
          className={classes.row}
          style={{
            paddingTop: "40%",
          }}
        >
          <CheckCircleOutlineIcon
            style={{
              width: 60,
              height: 60,
            }}
          ></CheckCircleOutlineIcon>
        </div>
        <div
          className={classes.row}
          style={{
            paddingTop: "5%",
          }}
        >
          <Typography
            style={{
              fontSize: 20,
              fontWeight: 600,
            }}
          >
            Profiel aangemaakt
          </Typography>
        </div>

        <div
          className={classes.row}
          style={{
            position: "absolute",
            bottom: "5%",
          }}
        >
          <MenuItem component={Link} to={"/profile"} className={classes.button}>
            <Typography
              style={{
                fontWeight: 600,
                fontSize: 16,
              }}
            >
              KLAAR
            </Typography>
          </MenuItem>
        </div>
      </div>
    );
  }
  return <div>error</div>;
}

export default Finalize;
