import { makeStyles, MenuItem, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { useEffect, useState } from "react";
import axios from "axios";
import API_URL from "../../../API_URL";
import Load from "../../load";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    height: "90%",
    width: "100%",
    paddingTop: "10%",
    background: theme.palette.primary.main,
    color: theme.palette.text.main,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
  },
  button: {
    width: "85%",
    backgroundColor: theme.palette.button.main,
    color: "white",
    fontWeight: "600",
    borderRadius: 20,
    justifyContent: "center",
  },
}));

export default function ConfirmView() {
  const classes = useStyles();

  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    setLoading(true);
    await axios
      .get(API_URL + "/logged", {
        params: {},
      })
      .then((res) => {
        if (res.data.logged == "true") {
          setLoggedIn(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("error: " + err);
        setLoggedIn(false);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <Load />;
  }

  if (loggedIn) {
    return (
      <div className={classes.main}>
        <div
          className={classes.row}
          style={{
            paddingTop: "20%",
          }}
        >
          <CheckCircleOutlineIcon
            style={{
              width: 60,
              height: 60,
            }}
          ></CheckCircleOutlineIcon>
        </div>
        <div
          className={classes.row}
          style={{
            paddingTop: "10%",
          }}
        >
          <Typography
            style={{
              fontSize: 20,
              fontWeight: 600,
            }}
          >
            Bedankt voor het
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography
            style={{
              fontSize: 20,
              fontWeight: 600,
            }}
          >
            bekijken van mijn
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography
            style={{
              fontSize: 20,
              fontWeight: 600,
            }}
          >
            verhaal.
          </Typography>
        </div>
        <div
          className={classes.row}
          style={{
            paddingTop: "10%",
          }}
        >
          <Typography
            style={{
              fontSize: 16,
            }}
          >
            Wil je de telefoon aan mij
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography
            style={{
              fontSize: 16,
            }}
          >
            terug geven?
          </Typography>
        </div>
        <div
          className={classes.row}
          style={{
            position: "absolute",
            bottom: "5%",
          }}
        >
          <MenuItem component={Link} to={"/"} className={classes.button}>
            <Typography
              style={{
                fontWeight: 600,
                fontSize: 16,
              }}
            >
              KLAAR
            </Typography>
          </MenuItem>
        </div>
      </div>
    );
  } else {
    return (
      <div className={classes.main}>
        <div
          className={classes.row}
          style={{
            paddingTop: "20%",
          }}
        >
          <CheckCircleOutlineIcon
            style={{
              width: 60,
              height: 60,
            }}
          ></CheckCircleOutlineIcon>
        </div>
        <div
          className={classes.row}
          style={{
            paddingTop: "10%",
          }}
        >
          <Typography
            style={{
              fontSize: 20,
              fontWeight: 600,
            }}
          >
            Bedankt voor het
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography
            style={{
              fontSize: 20,
              fontWeight: 600,
            }}
          >
            bekijken van mijn
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography
            style={{
              fontSize: 20,
              fontWeight: 600,
            }}
          >
            verhaal.
          </Typography>
        </div>
        <div
          className={classes.row}
          style={{
            position: "absolute",
            bottom: "5%",
          }}
        >
          <MenuItem component={Link} to={"/"} className={classes.button}>
            <Typography
              style={{
                fontWeight: 600,
                fontSize: 16,
              }}
            >
              KLAAR
            </Typography>
          </MenuItem>
        </div>
      </div>
    );
  }
}
