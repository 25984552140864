import { makeStyles, MenuItem, Typography } from "@material-ui/core";
import axios from "axios";
import { useEffect } from "react";
import API_URL from "../../../API_URL";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { useStoreActions } from "easy-peasy";
import Load from "../../load";

const useStyles = makeStyles((theme) => ({
  main: {
    background: theme.palette.primary.main,
    color: theme.palette.text.main,
    textAlign: "center",
    minHeight: "90vh",
    maxHeight: "90vh",
  },
  imageContainer: {
    minHeight: "60vh",
    maxHeight: "60vh",
    minWidth: "100%",
    maxWidth: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "50% 50%",
  },
  dataContainer: {
    background: theme.palette.primary.main,
    minHeight: "35vh",
    maxHeight: "35vh",
    width: "100%",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    position: "absolute",
    bottom: "0px",
    overflow: "hidden",
  },
  dataElement: {
    paddingTop: "5%",
  },
  dataButton: {
    width: "90%",
    justifyContent: "center",
    paddingLeft: "5%",
    paddingRight: "5%",
    borderRadius: "20px",
    background: "white",
    color: theme.palette.primary.tertiary,
  },
}));

async function GetData(id, secret) {
  const response = await axios
    .get(API_URL + "/profile/view", {
      params: {
        id: id,
        secret: secret,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("errrror: " + err);
    });

  return response;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export default function ProfileView() {
  const classes = useStyles();
  let query = useQuery();
  const profileId = query.get("profileId");
  const secret = query.get("secret");

  const setPageType = useStoreActions((actions) => actions.setPageType);

  const setProfileId = useStoreActions((actions) => actions.setProfileId);

  const setProfileName = useStoreActions((actions) => actions.setProfileName);

  const setProfileBirth = useStoreActions((actions) => actions.setProfileBirth);

  setPageType(0);
  setProfileId(parseInt(profileId));

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    try {
      setLoading(true);
      const dt = await GetData(profileId, secret);
      setData(dt);
      setProfileName(dt.data[0].first_name);
      setProfileBirth(getAge(dt.data[0].date_of_birth));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, []);

  if (loading) return <Load />;
  if (!data) return <div>error</div>;

  const item = data.data[0];
  console.log(item);

  return (
    <div className={classes.main}>
      <div
        className={classes.imageContainer}
        style={{
          backgroundImage: `url(${item.image})`,
        }}
      ></div>
      <div className={classes.dataContainer}>
        <div className={classes.dataElement}>
          <Typography
            style={{
              fontWeight: 600,
              fontSize: 22,
            }}
          >
            Hoi! Ik ben {item.first_name}.
          </Typography>
        </div>
        <div className={classes.dataElement}>
          <Typography>Ik wil graag mezelf voorstellen</Typography>
          <Typography>in 60 seconden.</Typography>
        </div>
        <div
          style={{
            width: "100%",
            paddingTop: "5%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <MenuItem
            className={classes.dataButton}
            component={Link}
            to={
              "/profile/view/about" //+
              //"?secret=" +
              //item.secret +
              //"&name=" +
              //item.first_name +
              //"&buttonText=Dit vind ik niet leuk"
            }
          >
            <Typography style={{ fontWeight: 600 }}>
              Ontdek wie ik ben
            </Typography>
            <ArrowRightIcon
              style={{
                justifyConten: "center",
              }}
            ></ArrowRightIcon>
          </MenuItem>
        </div>
      </div>
    </div>
  );
}
