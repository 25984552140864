import React, { Component } from "react";
import { post } from "axios";
import { FormControl, Typography } from "@material-ui/core";
import API_URL from "../../API_URL";

export default class FileUploadComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: "",
    };

    this.uploaded = false;

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.fileUpload = this.fileUpload.bind(this);
  }

  onChange(e) {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    this.createImage(files[0]);
  }

  createImage(file) {
    let reader = new FileReader();
    let url = reader.readAsDataURL(file);
    reader.onloadend = (e) => {
      this.setState({
        image: reader.result,
      });
      this.uploaded = true;
      this.props.onFileUploaded(this.state);
    };
  }

  onFormSubmit(e) {
    e.preventDefault();
    this.fileUpload(this.state.image);
  }

  fileUpload() {
    const url = API_URL + "fileupload";
    const formData = { file: this.state.image };
    return post(url, formData).then((response) => console.log(response));
  }

  render() {
    if (this.props.isShowImage) {
      if (this.state.image !== "" || this.props.inputImage !== "") {
        if (this.props.changeMode) {
          return (
            <div style={{ display: "flex" }}>
              <FormControl
                onSubmit={this.onFormSubmit}
                style={{
                  width: "100%",
                }}
              >
                <img
                  alt=""
                  src={
                    this.state.image !== ""
                      ? this.state.image
                      : this.props.inputImage
                  }
                  height="50%"
                  width="80%"
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                    borderTopRightRadius: "20px",
                    borderTopLeftRadius: "20px",
                    alignItems: "center",
                  }}
                />
                <div
                  style={{
                    paddingTop: "5%",
                  }}
                />
                <label
                  style={{
                    position: "relative",
                    width: "100%",
                    backgroundColor: "#98E0FC",
                    color: "white",
                    borderRadius: "20px",
                    justifyContent: "center",
                    textAlign: "center",
                    paddingTop: "1%",
                    paddingBottom: "1%",
                  }}
                >
                  <input
                    type="file"
                    onChange={this.onChange}
                    style={{
                      display: "none",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                  <Typography
                    style={{
                      fontWeight: "600",
                    }}
                  >
                    Andere Foto uploaden
                  </Typography>
                </label>
                {/* <button type="submit">Upload</button> */}
              </FormControl>
            </div>
          );
        } else {
          return (
            <div style={{ display: "flex" }}>
              <img
                alt=""
                src={
                  this.state.image !== ""
                    ? this.state.image
                    : this.props.inputImage
                }
                height="50%"
                width="80%"
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "contain",
                  borderTopRightRadius: "20px",
                  borderTopLeftRadius: "20px",

                  alignItems: "center",
                }}
              />
            </div>
          );
        }
      } else {
        if (!this.props.changeMode) {
          return <div />;
        }
        return (
          <div style={{ display: "flex" }}>
            <FormControl
              onSubmit={this.onFormSubmit}
              style={{
                width: "100%",
              }}
            >
              <img
                alt=""
                src={this.state.image}
                style={{
                  height: "auto",
                  width: "auto",
                  borderTopRightRadius: "20px",
                  borderTopLeftRadius: "20px",
                  alignItems: "center",
                }}
              />

              <label
                style={{
                  position: "relative",
                  width: "100%",
                  backgroundColor: "#98E0FC",
                  color: "white",
                  borderRadius: "20px",
                  justifyContent: "center",
                  textAlign: "center",
                  paddingTop: "1%",
                  paddingBottom: "1%",
                }}
              >
                <input
                  type="file"
                  onChange={this.onChange}
                  style={{
                    display: "none",
                    width: "100%",
                    height: "100%",
                  }}
                />
                <Typography
                  style={{
                    fontWeight: "600",
                  }}
                >
                  Foto Uploaden
                </Typography>
              </label>
              {/* <button type="submit">Upload</button> */}
            </FormControl>
          </div>
        );
      }
    } else {
      return (
        <div>
          <FormControl onSubmit={this.onFormSubmit}>
            <input
              type="file"
              onChange={this.onChange}
              className={this.classes.button}
            />
            {/* <button type="submit">Upload</button> */}
          </FormControl>
        </div>
      );
    }
  }
}
