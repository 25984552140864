import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import theme from "./theme";
import { ThemeProvider } from "@material-ui/core";
import { StoreProvider } from "easy-peasy";
import store from "./store";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { CookiesProvider } from "react-cookie";

ReactDOM.render(
  <CookiesProvider>
    <StoreProvider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </StoreProvider>
  </CookiesProvider>,
  document.getElementById("root")
);

serviceWorkerRegistration.register();
