import React from "react";
import { Redirect } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";

export default function Logout() {
  //cookies is defined but not used here, disregard this error
  const [cookies, removeCookie] = useCookies(["user"]);

  removeCookie("user");
  removeCookie("token");
  removeCookie("userId");

  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["Authorization"] = "Bearer ";

  // const clear = useStoreActions((action) => action.logout);
  // clear();

  return <Redirect to="/login" />;
}
