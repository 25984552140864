import { makeStyles, TextField, Typography, Button } from "@material-ui/core";
import axios from "axios";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router";
import API_URL from "../../../API_URL";
import FileUploadComponent from "../../media/FileUploadComponent";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { useCookies } from "react-cookie";
import Load from "../../load";

const useStyles = makeStyles((theme) => ({
  main: {
    background: theme.palette.primary.main,
    color: theme.palette.text.main,
    textAlign: "center",
    height: "90%",
    paddingTop: "5%",
    overflow: "auto",
    justifyContent: "center",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  text: {
    fontSize: "16px",
    paddingLeft: "10%",
  },
  textRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "left",
  },
  input: {
    position: "relative",
    width: "80%",
    color: "white",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    background: theme.palette.primary.main,
    color: theme.palette.text.main,
    textAlign: "left",
    height: "100%",
    paddingTop: "1%",
    paddingLeft: "10%",
    paddingRight: "10%",
  },
  innerContent: {
    paddingTop: "5%",
    maxHeight: "20%",
    width: "100%",
  },
  bottomContent: {
    position: "relative",
    width: "100%",
    paddingTop: "10%",
    paddingBottom: "5%",
  },
  button: {
    maxHeight: "20%",
    width: "80%",
    backgroundColor: theme.palette.button.main,
    color: "white",
    borderRadius: 20,
    justifyContent: "center",
  },

  textField: {
    width: "100%",
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

async function GetData(id, s) {
  const response = await axios
    .get(API_URL + "/profile/view", {
      params: {
        id: id,
        secret: s,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("errrror: " + err);
    });

  return response;
}

export default function Step0() {
  const classes = useStyles();

  const [cookies, setCookie] = useCookies(["user"]);

  const [userId, setUserId] = useState(0);
  const [profileId, setProfileId] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [image, setImage] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(new Date());
  const [secret, setSecret] = useState("");
  const [loading, setLoading] = useState(false);

  if (userId == 0) {
    setUserId(cookies.userId);
  }

  let query = useQuery();
  const queryProfileId = query.get("profileId");
  const querySecret = query.get("secret");

  //const [editable, ]
  const [title, setTitle] = useState("");

  const clear = useStoreActions((actions) => actions.clearProfile);
  clear([]);

  useEffect(async () => {
    if (profileId == 0 && queryProfileId && querySecret) {
      setLoading(true);
      setTitle("Profiel aanpassen");
      setSecret(querySecret);
      setProfileId(parseInt(queryProfileId));
      try {
        const dt = await GetData(queryProfileId, querySecret);
        var d = dt.data[0];
        setFirstName(d.first_name);
        setLastName(d.last_name);
        setImage(d.image);
        setDateOfBirth(d.date_of_birth);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    } else if (!profileId && !querySecret) {
      setTitle("Profiel aanmaken");
    }
  }, []);

  const saveProfile = useStoreActions((actions) => actions.saveProfile);
  const setProfileState = useStoreActions((actions) => actions.setProfileState);

  const [nextPage, setNextPage] = useState(false);

  const pageType = useStoreState((state) => state.createProfileState);

  function fileUploadCallback(event) {
    setImage(event.image);
  }

  async function HandleFormSubmit(event) {
    event.preventDefault();

    var data = {
      userId: userId,
      profileId: profileId,
      firstName: firstName,
      lastName: lastName,
      image: image,
      dateOfBirth: dateOfBirth,
      secret: secret,
    };

    //create
    if (profileId == 0) {
      await axios
        .post(API_URL + "/profile/create", data)
        .then((res) => {
          setProfileId(parseInt(res.data[0].id));
          data.profileId = res.data[0].id;
          saveProfile(data);
          setProfileState(pageType + 1);
          setNextPage(true);
        })
        .catch((err) => {
          console.log("error: " + err);
        });
    }
    //edit
    else {
      await axios
        .post(API_URL + "/profile/edit", data)
        .then((res) => {
          saveProfile(data);
          setProfileState(pageType + 1);
          setNextPage(true);
        })
        .catch((err) => {
          console.log("error: " + err);
        });
    }
  }

  if (loading) return <Load />;
  console.log("pageType " + pageType);
  if (nextPage) {
    return <Redirect to="/createProfile" />;
  }
  return (
    <div className={classes.main}>
      <form onSubmit={(event) => HandleFormSubmit(event)}>
        <div className={classes.row}>
          <Typography
            style={{
              fontSize: "26px",
            }}
          >
            {title}
          </Typography>
        </div>
        <div
          className={classes.textRow}
          style={{
            paddingTop: "5%",
          }}
        >
          <Typography className={classes.text}>Voornaam</Typography>
        </div>
        <div className={classes.row}>
          <TextField
            className={classes.input}
            type="text"
            id="firstName"
            variant="outlined"
            value={firstName}
            onChange={(event) => setFirstName(event.target.value)}
          ></TextField>
        </div>

        <div
          className={classes.textRow}
          style={{
            paddingTop: "5%",
          }}
        >
          <Typography className={classes.text}>Achternaam</Typography>
        </div>
        <div className={classes.row}>
          <TextField
            className={classes.input}
            type="text"
            id="lastName"
            variant="outlined"
            value={lastName}
            onChange={(event) => setLastName(event.target.value)}
          ></TextField>
        </div>

        <div
          className={classes.textRow}
          style={{
            paddingTop: "5%",
          }}
        >
          <Typography className={classes.text}>Geboortedatum</Typography>
        </div>
        <div className={classes.row}>
          <TextField
            className={classes.input}
            type="date"
            id="date"
            variant="outlined"
            value={dateOfBirth}
            onChange={(event) => setDateOfBirth(event.target.value)}
          ></TextField>
        </div>

        <div
          className={classes.textRow}
          style={{
            paddingTop: "5%",
          }}
        >
          <Typography className={classes.text}>Foto</Typography>
        </div>
        <div
          className={classes.row}
          style={{
            paddingLeft: "10%",
            paddingRight: "10%",
            justifyContent: "center",
          }}
        >
          <FileUploadComponent
            style={{
              paddingBottom: "5%",
            }}
            isShowImage={true}
            changeMode={true}
            inputImage={image}
            onFileUploaded={fileUploadCallback}
          />
        </div>
        <div
          className={classes.row}
          style={{
            paddingTop: "10%",
            paddingBottom: "5%",
          }}
        >
          <Button
            className={classes.button}
            type="submit"
            variant="contained"
            color="primary"
            style={{ width: "80%" }}
          >
            <Typography
              style={{
                fontWeight: "600",
              }}
            >
              {title}
            </Typography>
            <ArrowRightIcon
              style={{
                justifyConten: "center",
              }}
            ></ArrowRightIcon>
          </Button>
        </div>
      </form>
    </div>
  );
}
