import { makeStyles } from "@material-ui/core";
import logoImage from "./logo.png";
import "./Load.css";

const useStyles = makeStyles((theme) => ({
  main: {
    background: theme.palette.primary.main,
    color: theme.palette.text.main,
    height: "100%",

    display: "flex",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "90%",
    background: theme.palette.primary.main,
  },
}));

export default function Load() {
  const classes = useStyles();
  return (
    <div className={classes.center}>
      <img
        alt="logoimg"
        className="spin"
        src={logoImage}
        width="96"
        height="96"
      ></img>
    </div>
  );
}
