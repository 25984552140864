import {
  Button,
  makeStyles,
  MenuItem,
  TextareaAutosize,
  Typography,
} from "@material-ui/core";
import { useLocation } from "react-router";

import FileCopyIcon from "@material-ui/icons/FileCopy";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    height: "90%",
    width: "100%",
    paddingTop: "10%",
    background: theme.palette.primary.main,
    color: theme.palette.text.main,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
  },
  button: {
    maxHeight: "20%",
    width: "80%",
    padding: "7.5%",
    backgroundColor: theme.palette.primary.tertiary,
    color: "white",
    border: "2px solid white",
    borderRadius: 20,
  },
  backButton: {
    width: "85%",
    backgroundColor: theme.palette.button.main,
    color: "white",
    fontWeight: "600",
    borderRadius: 20,
    justifyContent: "center",
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function ShareURL() {
  const classes = useStyles();
  let query = useQuery();
  const profileId = query.get("profileId");
  const secret = query.get("secret");
  const shareUrl =
    "localhost:3000/profile/view?profileId=" + profileId + "&secret=" + secret;

  return (
    <div className={classes.main}>
      <div className={classes.row}>
        <Typography
          style={{
            fontSize: 20,
            fontWeight: 600,
          }}
        >
          Profiel delen met URL
        </Typography>
      </div>
      <div
        className={classes.row}
        style={{
          paddingTop: "15%",
        }}
      >
        <TextareaAutosize
          style={{
            width: "80%",
          }}
          value={shareUrl}
          onClick={() => {
            navigator.clipboard.writeText(shareUrl);
          }}
        ></TextareaAutosize>
      </div>
      <div
        className={classes.row}
        style={{
          paddingTop: "5%",
        }}
      >
        <Button
          className={classes.button}
          onClick={() => {
            navigator.clipboard.writeText(shareUrl);
          }}
        >
          <div className={classes.row}>
            <Typography
              style={{
                fontSize: 16,
                fontWeight: 600,
                width: "80%",
              }}
            >
              Kopieer
            </Typography>
            <FileCopyIcon></FileCopyIcon>
          </div>
        </Button>
      </div>

      <div
        className={classes.row}
        style={{
          position: "absolute",
          bottom: "5%",
        }}
      >
        <MenuItem
          className={classes.backButton}
          component={Link}
          to={"/profile/actions?profileId=" + profileId + "&secret=" + secret}
        >
          <Typography
            style={{
              fontWeight: 600,
              fontSize: 16,
            }}
          >
            KLAAR
          </Typography>
        </MenuItem>
      </div>
    </div>
  );
}
