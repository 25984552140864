import { action, createStore } from "easy-peasy";

// axios.defaults.withCredentials = true;

export default createStore({
  createProfile: {
    userId: 0,
    profileId: 0,
    firstName: "",
    lastName: "",
    image: "",
    dateOfBirth: "",
    entries: [],
  },
  pageType: 0,
  setPageType: action((state, payload) => {
    state.pageType = payload;
  }),
  profileId: 0,
  setProfileId: action((state, payload) => {
    state.profileId = payload;
  }),
  profileName: "",
  setProfileName: action((state, payload) => {
    state.profileName = payload;
  }),
  profileBirth: null,
  setProfileBirth: action((state, payload) => {
    state.profileBirth = payload;
  }),
  createProfileState: 0,
  setProfileState: action((state, payload) => {
    state.createProfileState = payload;
  }),
  viewProfileState: 0,
  setViewProfileState: action((state, payload) => {
    state.viewProfileState = payload;
  }),
  saveProfile: action((state, payload) => {
    state.createProfile.userId = payload.userId;
    state.createProfile.profileId = payload.profileId;
    state.createProfile.firstName = payload.firstName;
    state.createProfile.lastName = payload.lastName;
    state.createProfile.image = payload.image;
    state.createProfile.dateOfBirth = payload.dateOfBirth;
  }),
  saveEntries: action((state, payload) => {
    state.createProfile.entries.push(...payload);
  }),
  clearProfile: action((state, payload) => {
    state.createProfile.entries = [];
  }),
});
