import React from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Button, makeStyles, MenuItem, Typography } from "@material-ui/core";
import axios from "axios";
import API_URL from "../../API_URL";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import { useCookies } from "react-cookie";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Load from "../load";

const useStyles = makeStyles((theme) => ({
  main: {
    background: theme.palette.primary.main,
    color: theme.palette.text.main,
    textAlign: "center",
    minHeight: "100%",
    paddingTop: "10%",
    maxWidth: "100%",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  bottomContent: {
    paddingTop: "5%",
    paddingBottom: "5%",
    position: "relative",
    bottom: "5%",
    left: "10%",
    width: "80%",
  },
  profile: {
    background: theme.palette.primary.secondary,
    width: "100%",
  },
  profilePictureContainer: {
    width: "20%",
  },
  profilePicture: {
    position: "relative",
    width: "20%",
    height: "100%",
    borderRadius: 18,
  },
  container: {
    border: "1px solid white",
    borderRadius: 20,
    display: "flex",
    flexDirection: "row",
    background: theme.palette.primary.tertiary,
    width: "100%",
    height: "10%",
  },
  profileText: {
    width: "60%",
  },
  button: {
    position: "relative",
    width: "100%",
    backgroundColor: "white",
    color: theme.palette.primary.main,
    fontSize: "20px",
    fontWeight: "600",
    borderRadius: 8,
    justifyContent: "center",
  },
}));

async function GetProfileData(id) {
  const response = await axios
    .get(API_URL + "/profile", {
      params: {
        userId: id,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("errrror: " + err);
    });

  return response;
}

function Overview() {
  const classes = useStyles();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const [cookies, setCookie] = useCookies(["user"]);

  const setProfileState = useStoreActions((actions) => actions.setProfileState);
  setProfileState(0);

  useEffect(async () => {
    try {
      setLoading(true);
      console.log(cookies);
      const dt = await GetProfileData(cookies.userId);
      setData(dt);
      console.log(dt);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, []);

  if (loading) return <Load />;

  if (!data) {
    return (
      <div>
        <MenuItem
          className={classes.button}
          component={Link}
          to={"/createProfile/step0?profileId=-1"}
        >
          testlink create
        </MenuItem>
      </div>
    );
  }

  console.log(data.data.data);
  const items = data.data.data;

  const profileButton = items.map((item) => {
    return (
      <div
        key={item.id}
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <div
          style={{
            justifyContent: "center",
            width: "100%",
            height: "20%",
          }}
        >
          <MenuItem
            component={Link}
            to={
              "/profile/actions?profileId=" + item.id + "&secret=" + item.secret
            }
          >
            <div className={classes.container}>
              <div
                style={{
                  height: 100,
                  width: 100,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundImage: `url(${item.image})`,
                  borderRadius: 18,
                }}
              ></div>
              {/* <img
                src={item.image}
                className={classes.profilePicture}
                style={{
                  // flex: 1,
                  // aspectRatio: 1.5,
                  // resizeMode: "contain",
                  position: "relative",
                  maxWidth: "100%",
                  maxHeight: "100%",
                }}
                width="45"
                height="30"
              /> */}
              <div
                style={{
                  width: "60%",
                  textAlign: "left",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography>
                  {item.first_name + " " + item.last_name}
                </Typography>
              </div>
              <div
                style={{
                  width: "20%",
                  textAlign: "left",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <VisibilityIcon></VisibilityIcon>
              </div>
            </div>
          </MenuItem>
        </div>
      </div>
    );
  });

  return (
    <div className={classes.main}>
      <div
        className={classes.row}
        style={{
          paddingBottom: "5%",
        }}
      >
        <Typography
          style={{
            fontSize: 20,
            fontWeight: 600,
          }}
        >
          Profiel overzicht
        </Typography>
      </div>
      <div className={classes.profile}>{profileButton}</div>
      <div className={classes.bottomContent}>
        <MenuItem
          className={classes.button}
          component={Link}
          to={"/createProfile/step0"}
        >
          Nieuw Profiel aanmaken
        </MenuItem>
      </div>
    </div>
  );
}

export default Overview;
