import React from "react";
import { makeStyles, MenuItem } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import logoImage from "./logo.png";
import PeopleIcon from "@material-ui/icons/People";
import SettingsIcon from "@material-ui/icons/Settings";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { useCookies } from "react-cookie";

const useStyles = makeStyles((theme) => ({
  main: {
    background: theme.palette.primary.secondary,
    color: theme.palette.text.main,
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    height: "95%",
    overflow: "none",
  },
  image: {
    justifyContent: "center",
    width: "50%",
  },
  title: {
    background: theme.palette.primary.secondary,
    color: theme.palette.text.main,
    paddingTop: "10%",
    height: "30%",
    paddingBottom: "10%",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  content: {
    background: theme.palette.primary.main,
    borderTopRightRadius: "20px",
    borderTopLeftRadius: "20px",
    height: "100%",
  },
  button: {
    maxHeight: "20%",
    width: "80%",
    padding: "7.5%",
    backgroundColor: "white",
    color: theme.palette.primary.tertiary,
    borderRadius: 20,
  },
  borderButton: {
    maxHeight: "20%",
    width: "80%",
    padding: "7.5%",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    borderRadius: 20,
    border: "2px solid white",
  },
  menuItem: {
    textAlign: "center",
  },
}));

function Home() {
  const classes = useStyles();
  const [cookie, setCookie] = useCookies(["user"]);
  console.log(cookie);
  const username = cookie.userName;

  return (
    <div className={classes.main}>
      <div className={classes.title}>
        <div className={classes.row}>
          <img alt="logoimg" src={logoImage} width="96" height="96"></img>
        </div>
        <div
          className={classes.row}
          style={{ paddingTop: "5%", paddingBottom: "5%" }}
        >
          <Typography
            style={{
              fontSize: 20,
              fontWeight: 600,
            }}
          >
            Welkom terug {username}!
          </Typography>
        </div>
      </div>
      <div className={classes.content}>
        <div
          className={classes.row}
          style={{
            paddingTop: "10%",
          }}
        >
          <MenuItem className={classes.button} component={Link} to={"/profile"}>
            <PeopleIcon
              style={{
                justifyContent: "center",
                marginRight: "20%",
              }}
            ></PeopleIcon>
            <Typography
              style={{
                fontWeight: "600",
                justifyContent: "center",
              }}
            >
              Bekijk Profielen
            </Typography>
          </MenuItem>
        </div>

        <div
          className={classes.row}
          style={{
            paddingTop: "10%",
          }}
        >
          <MenuItem
            className={classes.borderButton}
            component={Link}
            to={"/settings"}
          >
            <SettingsIcon
              style={{
                justifyContent: "center",
                marginRight: "20%",
              }}
            ></SettingsIcon>
            <Typography
              style={{
                fontWeight: "600",
                justifyContent: "center",
              }}
            >
              Instellingen
            </Typography>
          </MenuItem>
        </div>

        <div
          className={classes.row}
          style={{
            paddingTop: "5%",
          }}
        >
          <MenuItem
            className={classes.borderButton}
            component={Link}
            to={"/info"}
          >
            <HelpOutlineIcon
              style={{
                justifyContent: "center",
                marginRight: "20%",
              }}
            ></HelpOutlineIcon>
            <Typography
              style={{
                fontWeight: "600",
                justifyContent: "center",
              }}
            >
              Over de app
            </Typography>
          </MenuItem>
        </div>
      </div>
    </div>
  );
}

export default Home;
