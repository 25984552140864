import React, { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Link, useHistory } from "react-router-dom";
import {
  MenuItem,
  Menu,
  withStyles,
  makeStyles,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import API_URL from "../API_URL";
import axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  link: {
    textDecoration: "none",
    color: "white",
    backgroundColor: "white",
  },
  toolbar: {
    padding: 0,
    backgroundColor: theme.palette.primary.tertiary,
  },
  div: {
    width: "50%",
  },
  menuItem: {
    justifyContent: "center",
  },
}));

function Nav() {
  const classes = useStyles();

  const [loggedIn, setLoggedIn] = useState(false);

  const [refresh, setRefresh] = useState(true);

  let history = useHistory();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    console.log("redirecting");
    window.location.href = "/logout";
  };

  history.listen((location) => {
    if (!refresh) setRefresh(true);
  });

  useEffect(async () => {
    if (refresh) {
      console.log("Check Auth");
      await axios
        .get(API_URL + "/logged", {
          params: {},
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.logged == "true") {
            console.log("LOGGED IN");
            setLoggedIn(true);
          }
        })
        .catch((err) => {
          console.log("error: " + err);
          setLoggedIn(false);
        });
      setRefresh(false);
    }
  });
  if (loggedIn) {
    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar className={classes.toolbar}>
            <div className={classes.div}>
              <Button onClick={() => history.goBack()}>
                <ArrowBackIcon
                  style={{
                    color: "white",
                  }}
                />
              </Button>
            </div>
            <div className={classes.div}>
              <MenuItem className={classes.menuItem} component={Link} to={"/"}>
                <HomeIcon />
              </MenuItem>
            </div>
            <div
              className={classes.div}
              style={{
                display: "flex",
                justifyContent: "right",
                alignItems: "right",
              }}
            >
              <Button className={classes.menuItem} onClick={handleClickOpen}>
                <LogoutIcon
                  style={{
                    color: "white",
                  }}
                />
              </Button>
              <Dialog open={open} onClose={handleClose}>
                <DialogTitle
                  style={{
                    textAlign: "center",
                  }}
                >
                  Uitloggen
                </DialogTitle>
                <DialogContent>
                  Weet je zeker dat je wilt uitloggen?
                </DialogContent>
                <DialogActions>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <Button
                      onClick={handleCancel}
                      style={{
                        width: "50%",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: 16,
                          fontweight: 600,
                        }}
                      >
                        Nee
                      </Typography>
                    </Button>
                    <Button
                      onClick={handleClose}
                      style={{
                        width: "50%",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: 16,
                          fontweight: 600,
                        }}
                      >
                        Ja
                      </Typography>
                    </Button>
                  </div>
                </DialogActions>
              </Dialog>
              {/* <MenuItem
                className={classes.menuItem}
                component={Link}
                to={"/logout"}
              >
                <LogoutIcon />
              </MenuItem> */}
            </div>
          </Toolbar>
        </AppBar>
      </div>
    );
  } else {
    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar className={classes.toolbar}>
            <div className={classes.div}>
              <MenuItem
                className={classes.menuItem}
                component={Link}
                to={"/register"}
              >
                <Typography
                  style={{
                    fontSize: "18px",
                    fontWeight: 600,
                  }}
                >
                  AANMELDEN
                </Typography>
              </MenuItem>
            </div>
            <div className={classes.div}>
              <MenuItem
                className={classes.menuItem}
                component={Link}
                to={"/login"}
              >
                <Typography
                  style={{
                    fontSize: "18px",
                    fontWeight: 600,
                  }}
                >
                  INLOGGEN
                </Typography>
              </MenuItem>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

export default Nav;
