import React from "react";
import { Route, Redirect, BrowserRouter, Switch } from "react-router-dom";
import Register from "./components/register";
import Login from "./components/login";
import Nav from "./components/nav";
import Home from "./components/home";
import Logout from "./components/logout";
import Overview from "./components/profile/overview";
import About from "./components/profile/about";
import CreateProfile from "./components/profile/createProfile";
import Step0 from "./components/profile/create/step0";
import Step1 from "./components/profile/create/step1";
import axios from "axios";
import Finalize from "./components/profile/create/finalize";
import ViewProfile from "./components/profile/viewProfile";
import ProfileView from "./components/profile/view/ProfileView";
import { useCookies } from "react-cookie";
import ProfileActions from "./components/profile/view/ProfileActions";
import confirmView from "./components/profile/view/confirmView";
import ShareURL from "./components/profile/view/shareUrl";
import ShareQR from "./components/profile/view/shareQr";
import Info from "./components/info";
import Settings from "./components/settings";
import Landing from "./components/landing";

const PrivateRoute = ({ path, component: Component, ...rest }) => {
  const [cookies, setCookie] = useCookies(["user"]);

  console.log(cookies);

  if (cookies.token) {
    axios.defaults.headers.common["Accept"] = "application/json";
    axios.defaults.headers.common["Authorization"] = "Bearer " + cookies.token;
  }

  if (cookies.userId == "undefined" || cookies.userId == null) {
    console.log("redirect");
    return <Redirect to={{ pathname: "/landing" }} />;
  }

  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        console.log(cookies.userId);
        return <Component {...props} {...rest} />;
      }}
    />
  );
};

axios.defaults.withCredentials = true;

class App extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <BrowserRouter>
        <div>
          <div>
            <Nav />
          </div>
          <Switch>
            <PrivateRoute path="/" exact component={Home} />
            <PrivateRoute path="/logout" exact component={Logout} />
            <PrivateRoute path="/profile" exact component={Overview} />
            <PrivateRoute
              path="/profile/actions"
              exact
              component={ProfileActions}
            />
            <PrivateRoute
              path="/profile/actions/shareUrl"
              exact
              component={ShareURL}
            />
            <PrivateRoute
              path="/profile/actions/shareQr"
              exact
              component={ShareQR}
            />
            <Route path="/profile/view" exact component={ProfileView} />
            <Route path="/profile/view/about" exact component={About} />
            <Route path="/profile/view/confirm" exact component={confirmView} />
            <Route path="/viewProfile" exact component={ViewProfile} />

            <PrivateRoute
              path="/createProfile"
              exact
              component={CreateProfile}
            />
            <PrivateRoute path="/createProfile/step0" exact component={Step0} />
            <PrivateRoute path="/createProfile/step1" exact component={Step1} />

            <PrivateRoute
              path="/createProfile/finalize"
              exact
              component={Finalize}
            />

            <PrivateRoute path="/info" exact component={Info} />
            <PrivateRoute path="/settings" exact component={Settings} />
            <Route path="/register" component={Register} />
            <Route path="/login" component={Login} />
            <Route path="/landing" component={Landing} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
