import { makeStyles, MenuItem, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  main: {
    background: theme.palette.primary.main,
    color: theme.palette.text.main,
    textAlign: "center",
    height: "100%",
    paddingTop: "10%",
    display: "flex",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
  },
  button: {
    maxHeight: "20%",
    width: "80%",
    padding: "7.5%",
    backgroundColor: "white",
    justifyContent: "center",
    color: theme.palette.primary.tertiary,
    borderRadius: 20,
  },
}));

export default function Info() {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <div className={classes.row}>
        <Typography
          style={{
            fontSize: "32px",
          }}
        >
          Over de app
        </Typography>
      </div>

      <div
        className={classes.row}
        style={{
          paddingTop: "5%",
        }}
      >
        <Typography
          style={{
            fontSize: "18px",
            fontWeight: 600,
            width: "80%",
          }}
        >
          Ieder mens heeft het recht gekend te worden. Soms gaat dat niet
          vanzelf, als je door een beperking, eem vorm van dementie of een
          andere reden de woorden niet vinden kunt. Hoe kun je dan vertellen wie
          je bent, wie je graag wilt zijn? Hoe kan je dan bij de wereld komen en
          hoe komt de wereld dan bij jou? Ebels.app zorgt met Meet me in a minute
          voor verbinding. Onze app laat zien wie je bent en kunt zijn. Zo is de
          wereld van en voor ons allemaal.
        </Typography>
      </div>

      <div
        className={classes.row}
        style={{
          paddingTop: "10%",
        }}
      >
        <MenuItem className={classes.button} component={Link} to={"/"}>
          <Typography
            style={{
              fontWeight: "600",
            }}
          >
            Terug
          </Typography>
        </MenuItem>
      </div>
    </div>
  );
}
