import { createMuiTheme } from "@material-ui/core";

export default createMuiTheme({
  overrides: {
    MuiTypography: {
      size1: {
        fontSize: [32, "!important"],
      },
    },
    MuiButton: {
      root: {
        borderRadius: 0,
      },
    },
  },
  body: {
    background: "rgb(205, 205, 205)",
  },
  typography: {
    fontFamily: "Open sans",
    // fontSize: 16
  },
  palette: {
    type: "light",
    primary: {
      main: "#9e77ed",
      secondary: "#875ed7",
      tertiary: "#7b55c6",
    },
    secondary: {
      // main: blue[600]
      main: "#875ed7",
    },
    tertiary: {
      main: "#000000",
    },
    text: {
      main: "white",
      secondary: "#9e77ed",
    },
    background: {
      main: "#f5f5f5",
      medium: "#181818",
      paper: "#fff",
      paperDark: "#bbbbbb",
    },
    success: {
      main: "#55B058",
    },
    button: {
      main: "#98E0FC",
    },
  },
});
