import { makeStyles, Typography, MenuItem } from "@material-ui/core";
import logoImage from "./logo.png";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  main: {
    background: theme.palette.primary.main,
    color: theme.palette.text.main,
    textAlign: "center",
    height: "100%",
    paddingTop: "10%",
    display: "flex",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    width: "80%",
    justifyContent: "center",
    paddingLeft: "10%",
    paddingRight: "10%",
  },
  image: {
    justifyContent: "center",
    width: "50%",
  },
  button: {
    maxHeight: "10%",
    width: "100%",
    padding: "5%",
    backgroundColor: "white",
    justifyContent: "center",
    color: theme.palette.primary.tertiary,
    borderRadius: 20,
  },
}));

export default function Landing() {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <div className={classes.row}>
        <Typography
          style={{
            fontSize: "20px",
          }}
        >
          Ebel
        </Typography>
      </div>

      <div
        className={classes.row}
        style={{
          paddingTop: "10%",
        }}
      >
        <img alt="logoimg" src={logoImage} width="96" height="96"></img>
      </div>
      <div
        className={classes.row}
        style={{
          paddingTop: "10%",
        }}
      >
        <Typography
          style={{
            fontSize: "16px",
          }}
        >
          Je moet ingelogd zijn om gebruik te kunnen maken van de app. Als je
          nog geen account hebt kan je een account aanmaken, bestaande
          gebruikers kunnen inloggen.
        </Typography>
      </div>

      <div
        className={classes.row}
        style={{
          paddingTop: "10%",
        }}
      >
        <MenuItem className={classes.button} component={Link} to={"/register"}>
          <Typography
            style={{
              fontWeight: 600,
              justifyContent: "center",
            }}
          >
            Aanmelden
          </Typography>
        </MenuItem>
      </div>
      <div
        className={classes.row}
        style={{
          paddingTop: "5%",
        }}
      >
        <MenuItem className={classes.button} component={Link} to={"/login"}>
          <Typography
            style={{
              fontWeight: 600,
              justifyContent: "center",
            }}
          >
            Inloggen
          </Typography>
        </MenuItem>
      </div>
    </div>
  );
}
