import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import API_URL from "../API_URL";
import { Redirect } from "react-router-dom";
import { useCookies } from "react-cookie";
import TOKEN_URL from "../TOKEN_URL";

var data = {
  username: "",
  password: "",
};

const useStyles = makeStyles((theme) => ({
  main: {
    background: theme.palette.primary.main,
    color: theme.palette.text.main,
    textAlign: "center",
    height: "100%",
    paddingTop: "10%",
    display: "flex",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
  },
  textRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "left",
  },
  text: {
    fontSize: "20px",
    paddingLeft: "10%",
  },
  input: {
    position: "relative",
    width: "80%",
    color: "white",
  },
  inputOutline: {
    borderColor: "white",
    borderWidth: 2,
    color: "white",
  },
  buttonInput: {
    position: "relative",
    width: "80%",
    backgroundColor: "white",
    color: theme.palette.primary.main,
    fontSize: "20px",
    fontWeight: "600",
    textTransform: "none",
    borderRadius: 8,
  },
}));

export default function Login() {
  const [cookies, setCookie] = useCookies(["user"]);

  const [redirectTo, setRedirect] = useState(false);

  const [open, setOpen] = useState(false);
  const [err, setErr] = useState("");

  const handleClose = () => {
    setErr("");
    setOpen(false);
  };

  async function HandleFormSubmit(event) {
    event.preventDefault();

    var cookieData = {
      userId: "",
      user: "",
      userName: "",
      token: "",
    };

    var success = false;
    await axios
      .get(TOKEN_URL + "sanctum/csrf-cookie")
      .then((response) => {
        success = true;

        console.log("CSRF RESPONSE");
        console.log(response);
      })
      .catch((err) => {
        console.log("error: " + err);
        return;
      });

    console.log(success);

    if (success === true) {
      success = false;
      await axios
        .post(API_URL + "/login", data)
        .then((res) => {
          console.log(res);
          var response = res.data;
          if (!response.token) {
            console.log("Login failed.");
            axios.defaults.headers.common["Authorization"] = "Bearer ";
          } else {
            cookieData.token = response.token;
            cookieData.userName = response.name;
            setCookie("token", cookieData.token);
            setCookie("userName", cookieData.userName);
            axios.defaults.headers.common["Accept"] = "application/json";
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + cookieData.token;
            console.log("Got token");
            success = true;
          }
        })
        .catch((err) => {
          console.log("error: " + err);
          setErr("Verkeerde gebruikersnaam of wachtwoord");
          setOpen(true);
        });

      if (success === true) {
        await axios
          .get(API_URL + "/userId", {
            params: {
              email: data.username,
            },
          })
          .then((res) => {
            console.log(res);
            if (res.data.data.id) {
              console.log(res.data.data.id);
              console.log("Got UserId");

              setCookie("userId", res.data.data.id);

              setCookie("user", data.username);

              console.log("Login cookies.");
              console.log(cookies);

              setRedirect(true);
            }
          })
          .catch((err) => {
            console.log("error: " + err);
            setErr("Onbekende fout");
            setOpen(true);
          });
      }
    }
  }

  const classes = useStyles();

  if (redirectTo) {
    console.log("redirect");
    return <Redirect to="/" />;
  }
  return (
    <div className={classes.main}>
      <form onSubmit={(event) => HandleFormSubmit(event)}>
        <div className={classes.row}>
          <Typography
            style={{
              fontSize: "32px",
            }}
          >
            Inloggen
          </Typography>
        </div>

        <div
          className={classes.textRow}
          style={{
            paddingTop: "15%",
          }}
        >
          <Typography className={classes.text}>E-mail</Typography>
        </div>
        <div className={classes.row}>
          <TextField
            className={classes.input}
            InputProps={{
              classes: {
                notchedOutline: classes.inputOutline,
              },
            }}
            placeholder="Je gebruikersnaam"
            type="text"
            id="name"
            variant="outlined"
            onChange={(event) => (data.username = event.target.value)}
          />
        </div>

        <div
          className={classes.textRow}
          style={{
            paddingTop: "5%",
          }}
        >
          <Typography className={classes.text}>Wachtwoord</Typography>
        </div>
        <div className={classes.row}>
          <TextField
            className={classes.input}
            InputProps={{
              classes: { notchedOutline: classes.inputOutline },
            }}
            placeholder="Je wachtwoord"
            type="password"
            id="password"
            variant="outlined"
            onChange={(event) => (data.password = event.target.value)}
          />
        </div>

        <div
          className={classes.row}
          style={{
            paddingTop: "5%",
          }}
        >
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle
              style={{
                textAlign: "center",
              }}
            >
              Oeps!
            </DialogTitle>
            <DialogContent>
              <Typography
                style={{
                  fontWeight: 600,
                  textAlign: "center",
                }}
              >
                {err}
              </Typography>
            </DialogContent>
            <DialogActions>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Button
                  onClick={handleClose}
                  style={{
                    width: "100%",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: 16,
                      fontweight: 600,
                      textAlign: "center",
                    }}
                  >
                    Oké
                  </Typography>
                </Button>
              </div>
            </DialogActions>
          </Dialog>
        </div>
        <div
          className={classes.row}
          style={{
            paddingTop: "10%",
          }}
        >
          <Button
            className={classes.buttonInput}
            type="submit"
            variant="contained"
            color="primary"
          >
            Inloggen
          </Button>
        </div>
      </form>
    </div>
  );
}
